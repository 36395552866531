import React from "react"
import Layout from "../components/layout"
import AuthorImage from "../images/me.png"
import SEO from "../components/seo"


const About = () => (
  <Layout cssClass="card">
        <SEO title="About me"></SEO>

    <img alt="" class="thirty-split left circle" src={AuthorImage}></img>

    <p>
      My earliest childhood memory is being in kindergarten or first grade and hiding behind a plastic crate in the hallway reading a book while my mother called me to dinner; I wasn't ready to put the book down and I thought she couldn't see me. I have always been fascinated with stories of all kinds and writing since my earliest memories. I kept hand written journals going back to before I was a teenager (many of which I still have hidden away).
        </p>
    <p>
      I didn't go to college to be a writer, I tried to be more pragmatic and I spent over a decade trying really hard to pretend that writing wasn't my passion. I finally gave in and let the creative little voice in my head start getting a say and suddenly I had my first ever manuscript. It was terrible and I am still in need of editing it to get it ready for alpha reading. I also started a second manuscript because that creative voice spent so long cooped up that it came out swinging with ideas that needed to be set free and written.
        </p>
    <p>
      I started this page as a place to collect my thoughts between those creative bursts. I stopped journal writing about ten years ago, but even while my life is mundane, my thoughts need some release. Not coincidentally, I met my husband nearly ten years ago now during my senior year of business school. I fell hard and fast and he has been my champion since the day I met him. I cheered him into his dream career and he encouraged me to chase after my creative voice in return.
        </p>
    <p>
      When not reading or writing, my other interests are playing with my adorable toddler, video games and relaxing with my family.
        </p>
  </Layout>

)

export default About;